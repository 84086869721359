$green: #28A038;
$theme-colors: (
  "primary": #06026D,
  "secondary": #007AFF,
  "success": #6CE14A,
  "info": #7938FE,
  "danger": #ff4136,
  "green": #3ACE01,
  "light-green": #D7F5CC,
  "tim": #44317B,
  "cancel": #6c757d,
);
@import "node_modules/bootstrap/scss/bootstrap";
.cursor-pointer {
  cursor: pointer;
}
.bg-cancel-gray {
  background-color: #ececec !important;
}
#admin-wrapper {
  table.product-table {
    background-color: #fff;
    tbody {
      td {
        font-size: 16px;
        .product-image {
          width: 88px;
          height: 88px;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }
}
.fz14 {
  font-size: 14px !important;
}
.fz16 {
  font-size: 16px !important;
}
table.product-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 7px;
  tbody {
    td {
      border: 1px solid #707070;
      padding: 7px;
      font-size: 12px;
      text-align: left;
      white-space: pre-line
    }
  }
}
.simple-style {
  width: 60px;
  display: inline-flex !important;
  padding: 0px 5px;
  input {
    border-radius: 0 !important;
    border: none;
    border-bottom: 1px solid #000 !important;
    background: none !important;
    height: 30px !important;
    padding: 0px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

.text-underline {
  text-decoration: underline;
}
